* {
  box-sizing: border-box !important;
  outline: none;
}

body {
  color: var(--text);
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

:root {
  --title: #211C6A;
  --text: #3D3D3D;
  --bgcolor: #F6FAFF;
  --btncolor: #FEB707;
}


.btn:focus,
.form-control:focus {
  box-shadow: none;
}

.logoimg {
  cursor: pointer;
}

.menu_name {
  padding: 10px 15px;
  color: #8d8d8d;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.menu_name:hover {
  background-color: #feb70747;
  border-radius: 10px;
  color: var(--title);
  font-weight: 600;
  font-size: 18px;
  /* border: 2px solid var(--btncolor); */
}

.active {
  color: var(--title);
  font-weight: 600;
  font-size: 18px;
  background-color: #feb70747;
  border-radius: 10px;
  border: 2px solid var(--btncolor);
}

.main_left h1 {
  color: var(--title);
  margin-bottom: 50px;
  font-weight: 600;
}

.main_left p {
  color: var(--text);
  margin-bottom: 50px;
}

.main_right {
  margin-bottom: 50px;
}

.btn_bg {
  background-color: #feb7073b;
  border-radius: 50px;
  padding: 10px 40px;
  font-weight: 600;
  border: 2px solid var(--btncolor);
  transition: all 0.5s ease;
}

.btn_bg:hover {
  background-color: var(--btncolor);
}

.section_pad {
  padding: 50px 0;
}

/* =============================== */
.about_content h2 {
  color: var(--title);
  font-weight: 600;
  margin-bottom: 15px;
}

.bottom_line {
  width: 100px;
  height: 3px;
  background-color: var(--title);
  margin-bottom: 30px;
}

.about_box img {
  margin-bottom: 20px;
}

.about_box h4 {
  color: var(--title);
  margin-bottom: 20px;
}

.box_line {
  background-color: var(--bgcolor);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.13)
}

.box-title {
  color: var(--title);
  margin-bottom: 10px;
  font-weight: 600;
}

.sec_color {
  background-color: #554EBD;
  color: #fff;
}

.sec_color .box-title {
  color: #fff;
}

.project_bg {
  background-image: url('./images/project_bg.png');
  height: 312px;
  margin-top: auto;
  background-position: center;
}

.our {
  color: #fff;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
}

.count {
  color: #fff;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.count p {
  font-size: 20px;
  margin-top: 10px;
}

.project_cen {
  display: flex;
  align-items: center;
  height: 100%;
}


/* ================================== */
.services_bg {
  background-color: var(--bgcolor);
}

.service_box {
  padding: 40px;
  background-color: #fff;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 25px;

}

.service_box img {
  margin-bottom: 20px;
}

.service_box h4 {
  color: var(--title);
  margin-bottom: 20px;
  font-weight: 600;
}

.career_title {
  color: var(--title);
  font-weight: 600;
  margin-bottom: 30px;
}

.career_bg {
  background-color: var(--bgcolor);
  display: flex;
  padding: 20px;
  align-items: center;
  color: var(--title);
  font-weight: 600;
  box-shadow: 4px 3px 12px rgba(45, 40, 126, 0.1);
  border-radius: 15px;
}

.career_bg p {
  font-size: 20px;
}


.career_box {
  padding: 40px;
  background-color: #fff;
  box-shadow: 3px 2px 36px rgba(45, 40, 126, 0.07);
  border-radius: 9px 60px;
  width: 100%;
}

.career_box img {
  margin-bottom: 20px;
}

.career_box h4 {
  color: var(--title);
  margin-bottom: 20px;
  font-weight: 600;
}

.career_box p {
  margin-bottom: 20px;
  font-weight: 500;
}

/* ====================================== */


.protfolio_bg {
  background-color: #726AEC;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.app_img {
  position: relative;
  bottom: 30px;
  left: 30px;
  width: 100%;
  z-index: 1
}

.app_img::after,
.app_img::before {
  position: absolute;
  content: "";
  width: 204px;
  height: 204px;
  border-radius: 20px;
  left: 20px;
  bottom: 20px;
  z-index: -1;
  transition: all 0.5s ease;
}

.app_img::after {
  background: #FEB707;
  left: 0px;
  bottom: 0px;
}

.app_img::before {
  border: 4px solid #fff;
  left: 0px;
  bottom: 0px;
}

.protfolio_bg:hover .app_img::after {
  left: 15px;
  bottom: 15px;
}

.protfolio_bg:hover .app_img::before {
  left: -15px;
  bottom: -15px;
}

.protfolio_bg:hover .app_img img {
  filter: drop-shadow(0px 0px 21px rgba(0, 0, 0, 0.26));
}

.app_cont {
  padding: 20px;
}

.app_img img {
  transition: all 0.5s ease;
}

.app_title {
  color: var(--title);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.app_detailes {
  color: var(--text);
}

.card {
  position: relative;
  background: #fff;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  text-align: center;
}

.face {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

.face1 {
  padding: 40px 20px;
  margin-bottom: 50px;
}

.face2 {
  background: #726AEC;
  transition: all 0.5s ease;
  border-radius: 15px;
  position: absolute;
}

.card:hover .face2 {
  height: 60px;
  border-radius: 0 0 15px 15px;
}

.face2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px 0 0 15px;
}

.logo_app_img {
  z-index: 10;
  margin-bottom: 20px;
  position: relative;
  top: 85px;
  transition: all 0.5s ease;
}

.card:hover .logo_app_img {
  top: 0px;
}

.icon {
  font-size: 40px;
  margin-right: 30px;
  color: var(--btncolor);
}

.form_box {
  resize: none;
  border: 1px solid #211C6A;
  border-radius: 10px;
  padding: 10px;
}

.copyrights {
  background: rgb(36, 36, 36);
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
}







.navbar_menu_heade {
  transition: all 0.5s ease;
  top: -60px;
}

.navbar_menu_heade.show {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(255, 255, 255);
  z-index: 102;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
}

.menu_per {
  display: flex;
}

.menu_icon {
  display: none;
  font-size: 30px;
  color: var(--title);
  z-index: 101;
}

.hiring {
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
}
.modal-dialog-bg {
  max-width: 900px;
}
.new-form {
  /* border: none; */
  width: 100%;
  font-size: 16px;
  background: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 3%) 0px 0px 10px 9px;
  margin-bottom: 5px; 
}
.sub-none {
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  padding: 0px;
  font-weight: 600;
}
.btn_close:hover{
  background-color: #ff895b;
  border: 2px solid #ff895b;
}
::-webkit-file-upload-button {
  /* background: none !important; */
  color: red !important;
}


/* =================   Admin   ========================= */
.height_full{
  height: 100vh;
}
.tableborder{
  border: 1px solid var(--title);
  overflow: hidden;
}
.tablescroll{
  overflow-x:scroll ;
  max-height: 90vh;
}
.col_width_1 {
 min-width: 150px;
}
.col_width_2 {
 min-width: 250px;
}
.col_width_3 {
 min-width: 200px;
}
.col_width_4 {
 min-width: 300px;
}
.table_head{
  color: #fff;
}
.table_head th{
  position: sticky;
  top: 0;
  background-color: #3D3D3D;
  z-index: 1;
}
th[scope=row] {
  position: sticky;
  left: 0;
  background-color: #979797;
  z-index: 2;
}
th[scope=col] {
  position: sticky;
  left: 0;
  background-color: #979797;
  z-index: 3;
}

.modal-confirm .icon-box {
  color: #fff;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 9;
  background: #82ce34;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
}
.modal-confirm .icon-box i {
  font-size: 58px;
  position: relative;
  top: 3px;
}
.modal-confirm.modal-dialog {
  height: 100vh;
  display: flex;
  align-items: center;
}
#modal{
  height: 100vh;
}
.modal-confirm {
  color: #636363;
  width: 325px;
  font-size: 14px;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -15px;
}
.btn_success_ok{
  background: #82ce34;
  color: #fff;
}
.alert{
  background: #82ce34;
  position: fixed;
  bottom: -60px;
  width: 100%;
  margin: 0;
  /* display: none; */
  transition: all 0.5s ease;
  z-index: 1061;
}
.alert_show{
  /* display: block; */
  bottom: 0px;
}

.error {
  color: red;
  font-size: 12px;
}



@media screen and (max-width : 992px) {
  .hiring {
    max-width: 120px;
  }
}

@media screen and (max-width : 820px) {
  .menu_per {
    position: fixed;
    top: 0;
    background: rgb(255, 255, 255);
    flex-direction: column;
    width: 60%;
    right: -90%;
    height: 100vh;
    z-index: 100;
    padding-top: 50px;
    transition: all 0.8s ease-in-out;
    box-shadow: 0px 0px 50px 11px rgb(0 0 0 / 13%);
  }

  .show_menu {
    right: 0;
  }

  .navbar_menu {
    padding: 20px;
  }

  .menu_icon {
    display: block;
  }

  .logoimg {
    max-width: calc(120px + (198 - 120) * (100vw - 320px) / (820 - 320));

  }
}

@media screen and (max-width : 768px) {
  .logo_app_img {
    width: 70%;
    top: 120px;
  }
}

@media screen and (max-width : 500px) {
  .menu_per {
    width: 80%;
  }

  .show_menu {
    right: 0;
  }

  .hiring {
    max-width: 80px;
  }
}

@media screen and (max-width : 375px) {
  .menu_per {
    width: 100%;
    right: -120%;
  }

  .show_menu {
    right: 0;
  }
}